import { ref } from 'vue'
import axios from 'axios'

let token = null

export const useAjax = () => {
  const message = ref(null)
  const errors = ref({})
  const submitting = ref(false)

  /**
   * 汎用的なGET処理
   */
  const callGet = async (path) => {
    const response = await axios.get(path)
    return response.data
  }

  /**
   * 汎用的なDELETE処理
   */
  const callDestroy = async (path, data = {}) => {
    await setCsrfToken()
    return await submit(async () => await axios.delete(path, convertParams(data)))
  }

  /**
   * 汎用的なPATCH処理
   */
  const callPatch = async (path, data) => {
    await setCsrfToken()
    return await submit(async () => await axios.patch(path, convertParams(data)))
  }

  /**
   * 汎用的なPATCH処理
   */
  const callPatchWithFile = async (path, name, data) => {
    const params = new FormData()
    generateParams(params, name, convertParams(data))

    return await submit(async () => await axios.patch(path, params))
  }

  /**
   * 汎用的なPOST処理
   */
  const callPost = async (path, data) => {
    await setCsrfToken()
    return await submit(async () => await axios.post(path, convertParams(data)))
  }

  /**
   * 汎用的なPOST処理
   */
  const callPostWithFile = async (path, name, data) => {
    const params = new FormData()
    generateParams(params, name, convertParams(data))
    await setCsrfToken()

    return await submit(async () => await axios.post(path, params))
  }

  /**
   * 送信するパラメータの調整
   * nullを空文字に変換して送信する
   * ※今のところNestedAttributesには未対応
   */
  const convertParams = (data) => {
    const result = Object.assign({}, data)
    Object.keys(result).forEach((k) => {
      if (Array.isArray(result[k])) {
        // 何もしない？
      }
      else if (result[k] == null) {
        result[k] = ''
      }
    })
    return result
  }

  /**
   * 送信用パラメータの生成（ファイルアップロード用）
   */
  const generateParams = (params, prefix, values) => {
    Object.keys(values).forEach((k) => {
      const key = `${prefix}[${k}]`
      if (Array.isArray(values[k])) {
        for (let i=0; i<values[k].length; i++) {
          generateParams(params, `${key}[${i}]`, values[k][i])
        }
      }
      else {
        params.append(key, values[k])
      }
    })
  }

  /**
   * CSRFトークンの取得
   */
  const getCsrfToken = async () => {
    if (token) {
      return token
    }

    const response = await callGet('/csrf.json')
    token = response.token

    return token
  }

  /**
   * リクエストヘッダーにCSRFトークンをセットする
   */
  const setCsrfToken = async () => {
    if (!token) {
      token = await getCsrfToken()
    }
    axios.defaults.headers['X-CSRF-TOKEN'] = token
  }

  /**
   * 更新系の処理呼び出し共通処理
   */
  const submit = async (proc) => {
    message.value = null
    errors.value = {}
    submitting.value = true

    try {
      const response = await proc()
      return response.data
    }
    catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        errors.value = e.response.data.errors
      }
      else {
        throw e
      }
    }
    finally {
      submitting.value = false
    }
  }

  return {
    // variables
    message,
    errors,
    submitting,
    // methods
    callGet,
    callDestroy,
    callPatch,
    callPost,
    getCsrfToken,
  }
}
